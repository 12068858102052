@import '../../assets/styles/fonts';
@import '../../assets/styles/colors';
@import '../../assets/styles/constants';
@import '../../assets/styles/mixins';
.Loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  .ant-spin-dot-item {
    background-color: #183762;
  }
}
