@import 'fonts';
@import 'colors';
@import 'constants';
@import 'mixins';
@import './normalize';
@import './reset';
@import './constants';
@import './mixins';
@import './rcp.css';
@import './glide-data-grid.css';
@import './carousel.min.css';
@import './ReactContexify.css';
@import './styles-compiled.css';

html {
  font-family: sans-serif;
  font-size: 10px;
}
body {font-family: 'Be Vietnam Pro', 'Open Sans', --apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif}
/* In your global stylesheet (e.g., styles.css) */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 14px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(linear,
                    left bottom,
                    left top,
                    color-stop(0.44, #252E45),
                    color-stop(0.72, #252E45),
                    color-stop(0.86, #252E45));
}
.ant-tooltip-inner{
  font-size: 12px;
}

.buttonGuide{
  button{
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0;
    img{
      width: 24px;
    }
  }
}
.custom-overlay {
  display: none;
}
.SelectorList{
  display: none;
}
html,
body {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Be Vietnam Pro', 'Open Sans';
}
a, button {
  &:hover {
    opacity: 0.7 !important;
  }
}
button {
  @include mono-label-medium;
  padding: 16px;
  background: $primary-d1;
  color: $primary-l6;
  border-width: 0;
  border-radius: 8px;
  cursor: pointer;
}
.container {
  width: calc(100%);
  margin: 0 auto;
}
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.nowrap {
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.card {
  background: #FFF;
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 20px 20px;
  flex-direction: column;
  flex: 1 0 0;
  min-height: 50vh;
  border: 2px solid rgba(19, 59, 96, 0.20);
}

.heading-status {
  position: relative;
  @include label-small;
  margin-left: 20px;
	&::after {
		content: '';
		width: 12px;
		height: 12px;
		background-color: $primary-o;
		border-radius: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -20px;
	}
  &.create {
    &::after {
      background-color: $semantics-success-0;
    }
  }
  &.edit {
    &::after {
      background-color: $semantics-info-0;
    }
  }
  &.delete {
    &::after {
      background-color: $semantics-danger-0;
    }
  }
}

.Context {
  @include label-x-small;
  color: $neutral-10;
  position: absolute;
  z-index: 1000;
  border-radius: var(--1, 4px);
  border: 1px solid #DEE4EC;
  background: var(--default-white, #FFF);
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.15);
  hr {
    background: #3a3d40;
    opacity: 0.1;
    height: 0.5px;
    width: 100%;
    margin: 0;
  }
  span {
    display: block;
    margin-right: 8px;
  }
  div {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    gap: 6px;
    &:hover {
      opacity: 0.5;
    }
  }
  &-delete {
    color: #FB3030 !important;
    span, li, div {
      color: #FB3030 !important;
    }
  }
}
.pad.gdg-style:has(.rcp) {
  padding: 0 !important;
  overflow: hidden !important;
  border-radius: 10px !important;
  box-shadow: 0 0 0 3px #c0c0c0, 0px 0px 3px rgba(62, 65, 86, 0.4), 0px 6px 12px rgba(62, 65, 86, 0.15) !important;
  max-width: fit-content !important;
}

.ant-modal-body {
  overflow: hidden;
  border-width: 1px;
  border-color: #304ba7;
  border-style: solid;
}
.ant-modal-mask{
  background-color: rgb(0 0 0 / 78%) !important;
}
.paragraph-large {
  @include paragraph-large;
}

.paragraph-medium {
  @include paragraph-medium;
}

.paragraph-small {
  @include paragraph-small;
}

.paragraph-x-small {
  @include paragraph-x-small;
}

.label-large {
  @include label-large;
}

.label-medium {
  @include label-medium;
}

.label-small {
  @include label-small;
}

.label-x-small {
  @include label-x-small;
}

.mono-label-large {
  @include mono-label-large;
}

.mono-label-medium {
  @include mono-label-medium;
}

.mono-label-small {
  @include mono-label-small;
}

.mono-label-x-small {
  @include mono-label-x-small;
}

.heading-xx-large {
  @include heading-xx-large;
}

.heading-x-large {
  @include heading-x-large;
}

.heading-large {
  @include heading-large;
}

.heading-medium {
  @include heading-medium;
}

.heading-small {
  @include heading-small;
}

.heading-x-small {
  @include heading-x-small;
}
.heading-little {
  @include heading-little;
}
.reactour__popover {
  font-size: 13px;
  line-height: 160%;
  z-index: 1000011111 !important;
  background-color: #FFFFDC !important;
  border: solid 1px #a7a0a0;
  color: black !important;
}
.reactour__popover:after {
  content: "";
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 1px 14px rgba(0,0,0,.2);
}
.reactour__popover:before {
  content: "";
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  background: #FFFFDC;
  position: absolute;
  z-index: -1;
}

.reactour__popover.right:before {
  border-left: solid 1px #a7a0a0;
  border-bottom: solid 1px #a7a0a0;
  top: 15px;
  left: -11px;
}

.reactour__popover.left:before {
  border-right: solid 1px #a7a0a0;
  border-top: solid 1px #a7a0a0;
  top: 15px;
  right: -11px;
}

.reactour__popover.top:before {
  border-right: solid 1px #a7a0a0;
  border-bottom: solid 1px #a7a0a0;
  bottom: -11px;
}

.reactour__popover.bottom:before {
  border-left: solid 1px #a7a0a0;
  border-top: solid 1px #a7a0a0;
  top: -11px;
  right: 20px;
}

.no-scroll {
  overflow: hidden;
  position: fixed; // This can help in certain cases, especially on iOS
  width: 100%;
}
nav {
  box-shadow: -2px 5px 12px 10px rgba(0, 0, 0, 0.05);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 124%;
    height: 78px;
    left: -20%;
    right: 0;
    top: 0;
    z-index: 0;
    filter: contrast(1.2);
    border-radius: 6px 6px 0px 0px;
    background: linear-gradient(180deg, #252E45 0%, #141C30 99.97%);
}
}
.Login nav::after {
  content: none;
}
.nav-fixed {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000000000 !important; // Adjust z-index as needed
  // Add any additional styling you want for the fixed nav bar
  &-tour {
    z-index: 1000000000 !important; // Adjust z-index as needed

  }
}


.DataGrid {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-image: -webkit-gradient(linear,
                      left bottom,
                      left top,
                      color-stop(0.44, #737383),
                      color-stop(0.72, #737383),
                      color-stop(0.86, #737383));
  }
}

.ant-modal.ant-modal-confirm-warning {
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.ant-message {
  z-index: 999999999999 !important;
}
.disable .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  pointer-events: all;
}
