@import url('/src/assets/fonts/BeVietnamPro/stylesheet.css');
  
@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?4zzvsa');
	src:  url('../fonts/icomoon.eot?4zzvsa#iefix') format('embedded-opentype'),
		url('../fonts/icomoon.ttf?4zzvsa') format('truetype'),
		url('../fonts/icomoon.woff?4zzvsa') format('woff'),
		url('../fonts/icomoon.svg?4zzvsa#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
/* use !important to prevent issues with browser extensions that change fonts */
font-family: 'icomoon' !important;
speak: never;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;

/* Better Font Rendering =========== */
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
.icon-drag:before {
content: "\e90b";
}
.icon-run {
transform: rotate(180deg);
&:before {
	content: "\e90c";
	}
}
.icon-add:before {
content: "\e900";
}
.icon-bookmark:before {
content: "\e901";
}
.icon-copy:before {
content: "\e902";
}
.icon-document:before {
content: "\e903";
}
.icon-edit:before {
content: "\e904";
}
.icon-export:before {
content: "\e905";
}
.icon-filter:before {
content: "\e906";
}
.icon-import:before {
content: "\e907";
}
.icon-plus:before {
content: "\e908";
}
.icon-setting:before {
content: "\e909";
}
.icon-trash:before {
content: "\e90a";
}

@mixin paragraph-large {
	font-size: 18px;
	font-family: "Be Vietnam Pro";
	font-weight: 400;
	font-style: normal;
	line-height: 140%;
	text-decoration: none;
	text-transform: none;
}
@mixin paragraph-medium {
	font-size: 16px;
	font-family: "Be Vietnam Pro";
	font-weight: 400;
	font-style: normal;
	line-height: 140%;
	text-decoration: none;
	text-transform: none;
}
@mixin paragraph-small {
	font-size: 14px;
	font-family: "Be Vietnam Pro";
	font-weight: 400;
	font-style: normal;
	line-height: 140%;
	text-decoration: none;
	text-transform: none;
}
@mixin paragraph-x-small {
	font-size: 12px;
	font-family: "Be Vietnam Pro";
	font-weight: 400;
	font-style: normal;
	line-height: 140%;
	text-decoration: none;
	text-transform: none;
}
@mixin label-large {
	font-size: 18px;
	font-family: "Be Vietnam Pro";
	font-weight: 600;
	font-style: normal;
	line-height: 140%;
	text-decoration: none;
	text-transform: none;
}
@mixin label-medium {
	font-size: 16px;
	font-family: "Be Vietnam Pro";
	font-weight: 600;
	font-style: normal;
	line-height: 140%;
	text-decoration: none;
	text-transform: none;
}
@mixin label-small {
	font-size: 12.8px;
	font-family: "Be Vietnam Pro";
	font-weight: 600;
	font-style: normal;
	// line-height: 140%;
	text-decoration: none;
	text-transform: none;
}
@mixin label-x-small {
	font-size: 12px;
	font-family: "Be Vietnam Pro";
	font-weight: 600;
	font-style: normal;
	line-height: 140%;
	text-decoration: none;
	text-transform: none;
}
@mixin mono-label-large {
	font-size: 18px;
	font-family: "Be Vietnam Pro";
	font-weight: 500;
	font-style: normal;
	line-height: 120%;
	text-decoration: none;
	text-transform: none;
}
@mixin mono-label-medium {
	font-size: 16px;
	font-family: "Be Vietnam Pro";
	font-weight: 500;
	font-style: normal;
	line-height: 120%;
	text-decoration: none;
	text-transform: none;
}
@mixin mono-label-small {
	font-size: 14px;
	font-family: "Be Vietnam Pro";
	font-weight: 500;
	font-style: normal;
	line-height: 120%;
	text-decoration: none;
	text-transform: none;
}
@mixin mono-label-x-small {
	font-size: 12px;
	font-family: "Be Vietnam Pro";
	font-weight: 500;
	font-style: normal;
	line-height: 120%;
	text-decoration: none;
	text-transform: none;
}
@mixin heading-xx-large {
	font-size: 40px;
	font-family: "Be Vietnam Pro";
	font-weight: 600;
	font-style: normal;
	line-height: 120%;
	text-decoration: none;
	text-transform: none;
}
@mixin heading-x-large {
	font-size: 36px;
	font-family: "Be Vietnam Pro";
	font-weight: 600;
	font-style: normal;
	line-height: 120%;
	text-decoration: none;
	text-transform: none;
}
@mixin heading-large {
	font-size: 32px;
	font-family: "Be Vietnam Pro";
	font-weight: 600;
	font-style: normal;
	line-height: 120%;
	text-decoration: none;
	text-transform: none;
}
@mixin heading-medium {
	font-size: 28px;
	font-family: "Be Vietnam Pro";
	font-weight: 600;
	font-style: normal;
	line-height: 120%;
	text-decoration: none;
	text-transform: none;
}
@mixin heading-small {
	font-size: 24px;
	font-family: "Be Vietnam Pro";
	font-weight: 600;
	font-style: normal;
	line-height: 120%;
	text-decoration: none;
	text-transform: none;
}
@mixin heading-x-small {
	font-size: 20px;
	font-family: "Be Vietnam Pro";
	font-weight: 600;
	font-style: normal;
	line-height: 120%;
	text-decoration: none;
	text-transform: none;
}
@mixin heading-little {
	font-family: "Be Vietnam Pro";
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
}