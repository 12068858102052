/*
- Please follow this tool to name your color, then please define the color in src/common-types/ui.ts also
  https://chir.ag/projects/name-that-color/#6195ED

- If colors same name, please add suffix. Ex: persian-blue, persian-blue-dark, persian-blue-light, ...
*/

$white: #ffffff;
$black: #000000;
 /* Variables  */
 $spacing-0: 0px;
 $spacing-4: 4px;
 $spacing-6: 6px;
 $spacing-12: 12px;
 $spacing-16: 16px;
 $spacing-18: 18px;
 $spacing-20: 20px;
 $spacing-24: 24px;
 $spacing-32: 32px;
 $spacing-40: 40px;
 $spacing-48: 48px;
 $spacing-64: 64px;
 $spacing-80: 80px;
 $spacing-128: 128px;
 $the-scales-amber-step-12: #4E2009FF;
 $the-scales-amber-step-11: #AD5700FF;
 $the-scales-amber-step-10: #FFA01CFF;
 $the-scales-amber-step-9: #FFB224FF;
 $the-scales-amber-step-8: #EE9D2BFF;
 $the-scales-amber-step-7: #F3BA63FF;
 $the-scales-amber-step-6: #FFD386FF;
 $the-scales-amber-step-5: #FEF2A4FF;
 $the-scales-amber-step-4: #FFECBCFF;
 $the-scales-amber-step-3: #FFF4D5FF;
 $the-scales-amber-step-2: #FFF9EDFF;
 $the-scales-amber-step-1: #FEFDFBFF;
 $the-scales-yellow-step-12: #35290FFF;
 $the-scales-yellow-step-11: #F0C000FF;
 $the-scales-yellow-step-10: #F7CE00FF;
 $the-scales-yellow-step-9: #F5D90AFF;
 $the-scales-yellow-step-8: #EBBC00FF;
 $the-scales-yellow-step-7: #EFD36CFF;
 $the-scales-yellow-step-6: #F9E68CFF;
 $the-scales-yellow-step-5: #FEF2A4FF;
 $the-scales-yellow-step-4: #FFF8BBFF;
 $the-scales-yellow-step-3: #FFFBD1FF;
 $the-scales-yellow-step-2: #F7FCF0FF;
 $the-scales-yellow-step-1: #FDFDF9FF;
 $the-scales-lime-step-12: #263209FF;
 $the-scales-lime-step-11: #5D770DFF;
 $the-scales-lime-step-10: #93C926FF;
 $the-scales-lime-step-9: #99D52AFF;
 $the-scales-lime-step-8: #94BA2CFF;
 $the-scales-lime-step-7: #B1D16AFF;
 $the-scales-lime-step-6: #C9E894FF;
 $the-scales-lime-step-5: #D7F2B0FF;
 $the-scales-lime-step-4: #E4F7C7FF;
 $the-scales-lime-step-3: #EEFADCFF;
 $the-scales-lime-step-2: #F7FCF0FF;
 $the-scales-lime-step-1: #FCFDFAFF;
 $the-scales-mint-step-10: #69D9C1FF;
 $the-scales-mint-step-9: #70E1C8FF;
 $the-scales-mint-step-5: #C0EFE3FF;
 $the-scales-mint-step-4: #D2F7EDFF;
 $the-scales-mint-step-6: #A5E4D4FF;
 $the-scales-mint-step-3: #E1FBF4FF;
 $the-scales-mint-step-1: #F9FEFDFF;
 $the-scales-mint-step-11: #147D6FFF;
 $the-scales-mint-step-2: #EFFEFAFF;
 $the-scales-mint-step-8: #40C4AAFF;
 $the-scales-mint-step-7: #7DD4C0FF;
 $the-scales-mint-step-12: #09342EFF;
 $the-scales-pink-step-11: #CD1D8DFF;
 $the-scales-pink-step-2: #FFF7FCFF;
 $the-scales-pink-step-12: #3B0A2AFF;
 $the-scales-pink-step-1: #FFFCFEFF;
 $the-scales-pink-step-7: #ECADD4FF;
 $the-scales-pink-step-9: #D6409FFF;
 $the-scales-pink-step-8: #E38EC3FF;
 $the-scales-pink-step-4: #FCE5F3FF;
 $the-scales-pink-step-6: #F3C6E2FF;
 $the-scales-pink-step-3: #FEEEF8FF;
 $the-scales-pink-step-5: #F9D8ECFF;
 $the-scales-pink-step-10: #D23197FF;
 $the-scales-green-step-7: #92CEACFF;
 $the-scales-green-step-4: #DDF3E4FF;
 $the-scales-green-step-3: #E9F9EEFF;
 $the-scales-green-step-2: #F2FCF5FF;
 $the-scales-green-step-1: #FBFEFCFF;
 $the-scales-green-step-12: #153226FF;
 $the-scales-green-step-11: #18794EFF;
 $the-scales-green-step-9: #30A46CFF;
 $the-scales-green-step-8: #5BB98CFF;
 $the-scales-green-step-6: #B4DFC4FF;
 $the-scales-green-step-5: #CCEBD7FF;
 $the-scales-green-step-10: #299764FF;
 $the-scales-teal-step-12: #10302BFF;
 $the-scales-teal-step-11: #067A6FFF;
 $the-scales-teal-step-10: #0E9888FF;
 $the-scales-teal-step-9: #12A594FF;
 $the-scales-teal-step-8: #53B9ABFF;
 $the-scales-teal-step-7: #8DCEC3FF;
 $the-scales-teal-step-6: #AFDFD7FF;
 $the-scales-teal-step-5: #C7EBE5FF;
 $the-scales-teal-step-4: #D9F3EEFF;
 $the-scales-teal-step-3: #E7F9F5FF;
 $the-scales-teal-step-2: #F1FCFAFF;
 $the-scales-teal-step-1: #FAFEFDFF;
 $the-scales-cyan-step-12: #04313CFF;
 $the-scales-cyan-step-11: #0C7792FF;
 $the-scales-cyan-step-10: #0894B3FF;
 $the-scales-cyan-step-9: #05A2C2FF;
 $the-scales-cyan-step-8: #3DB9CFFF;
 $the-scales-cyan-step-7: #84CDDAFF;
 $the-scales-cyan-step-6: #AADEE6FF;
 $the-scales-cyan-step-5: #C4EAEFFF;
 $the-scales-cyan-step-4: #D8F3F6FF;
 $the-scales-cyan-step-3: #E7F9FBFF;
 $the-scales-cyan-step-2: #F2FCFDFF;
 $the-scales-cyan-step-1: #FAFDFEFF;
 $the-scales-blue-step-12: #00254DFF;
 $the-scales-blue-step-11: #006ADCFF;
 $the-scales-blue-step-10: #0081F1FF;
 $the-scales-blue-step-9: #0091FFFF;
 $the-scales-blue-step-8: #5EB0EFFF;
 $the-scales-blue-step-7: #96C7F2FF;
 $the-scales-blue-step-6: #B7D9F8FF;
 $the-scales-blue-step-5: #CEE7FEFF;
 $the-scales-blue-step-4: #E1F0FFFF;
 $the-scales-blue-step-3: #EDF6FFFF;
 $the-scales-blue-step-2: #F5FAFFFF;
 $the-scales-blue-step-1: #FBFDFFFF;
 $the-scales-gray-step-10: #858585FF;
 $the-scales-gray-step-9: #8F8F8FFF;
 $the-scales-gray-step-6: #E2E2E2FF;
 $the-scales-gray-step-5: #E8E8E8FF;
 $the-scales-gray-step-12: #171717FF;
 $the-scales-gray-step-11: #6F6F6FFF;
 $the-scales-gray-step-2: #F8F8F8FF;
 $the-scales-gray-step-1: #FCFCFCFF;
 $the-scales-gray-step-8: #C7C7C7FF;
 $the-scales-gray-step-7: #DBDBDBFF;
 $the-scales-gray-step-4: #EDEDEDFF;
 $the-scales-gray-step-3: #F3F3F3FF;
 $the-scales-indigo-step-12: #101D46FF;
 $the-scales-indigo-step-11: #3451B2FF;
 $the-scales-indigo-step-10: #3A5CCCFF;
 $the-scales-indigo-step-9: #3E63DDFF;
 $the-scales-indigo-step-8: #8DA4EFFF;
 $the-scales-indigo-step-7: #AEC0F5FF;
 $the-scales-indigo-step-6: #C6D4F9FF;
 $the-scales-indigo-step-5: #D9E2FCFF;
 $the-scales-indigo-step-4: #E6EDFEFF;
 $the-scales-indigo-step-3: #F0F4FFFF;
 $the-scales-indigo-step-2: #F8FAFFFF;
 $the-scales-indigo-step-1: #FDFDFEFF;
 $the-scales-brown-step-5: #EFDDCCFF;
 $the-scales-brown-step-8: #D09E72FF;
 $the-scales-brown-step-6: #E8CDB5FF;
 $the-scales-brown-step-1: #FEFDFCFF;
 $the-scales-brown-step-4: #F4E9DDFF;
 $the-scales-brown-step-7: #DDB896FF;
 $the-scales-brown-step-2: #FCF9F6FF;
 $the-scales-brown-step-3: #F8F1EAFF;
 $the-scales-brown-step-12: #3F2C22FF;
 $the-scales-brown-step-11: #886349FF;
 $the-scales-brown-step-10: #A07653FF;
 $the-scales-brown-step-9: #AD7F58FF;
 $the-scales-violet-step-12: #20134BFF;
 $the-scales-violet-step-11: #5746AFFF;
 $the-scales-violet-step-10: #644FC1FF;
 $the-scales-violet-step-9: #6E56CFFF;
 $the-scales-violet-step-6: #D7CFF9FF;
 $the-scales-violet-step-7: #C4B8F3FF;
 $the-scales-violet-step-2: #FBFAFFFF;
 $the-scales-violet-step-8: #AA99ECFF;
 $the-scales-violet-step-3: #F5F2FFFF;
 $the-scales-violet-step-1: #FDFCFEFF;
 $the-scales-violet-step-4: #EDE9FEFF;
 $the-scales-violet-step-5: #E4DEFCFF;
 $the-scales-orange-step-12: #451E11FF;
 $the-scales-orange-step-11: #BD4B00FF;
 $the-scales-orange-step-9: #F76808FF;
 $the-scales-orange-step-8: #FA934EFF;
 $the-scales-orange-step-7: #FFB381FF;
 $the-scales-orange-step-6: #FFCCA7FF;
 $the-scales-orange-step-5: #FFDCC3FF;
 $the-scales-orange-step-4: #FFE8D7FF;
 $the-scales-orange-step-3: #FFF1E7FF;
 $the-scales-orange-step-2: #FEF8F4FF;
 $the-scales-orange-step-1: #FEFCFBFF;
 $the-scales-orange-step-10: #ED5F00FF;
 $the-scales-crimson-step-10: #E03177FF;
 $the-scales-crimson-step-9: #E93D82FF;
 $the-scales-crimson-step-6: #F4C6DBFF;
 $the-scales-crimson-step-5: #F9D8E7FF;
 $the-scales-crimson-step-12: #3D0D1DFF;
 $the-scales-crimson-step-11: #D31E66FF;
 $the-scales-crimson-step-2: #FFF7FBFF;
 $the-scales-crimson-step-1: #FFFCFDFF;
 $the-scales-crimson-step-8: #E58FB1FF;
 $the-scales-crimson-step-7: #EDADC8FF;
 $the-scales-crimson-step-4: #FCE5F0FF;
 $the-scales-crimson-step-3: #FEEFF6FF;
 $the-scales-sky-step-12: #003242FF;
 $the-scales-sky-step-11: #0078A1FF;
 $the-scales-sky-step-10: #5FD4F4FF;
 $the-scales-sky-step-9: #68DDFDFF;
 $the-scales-sky-step-8: #2EBDE5FF;
 $the-scales-sky-step-7: #79CFEAFF;
 $the-scales-sky-step-6: #A4DFF1FF;
 $the-scales-sky-step-5: #C1ECF9FF;
 $the-scales-sky-step-4: #D5F4FDFF;
 $the-scales-sky-step-3: #E4F9FFFF;
 $the-scales-sky-step-2: #F1FCFFFF;
 $the-scales-sky-step-1: #F9FEFFFF;
 $the-scales-purple-step-10: #8445BCFF;
 $the-scales-purple-step-11: #793AAFFF;
 $the-scales-purple-step-6: #E3CCF4FF;
 $the-scales-purple-step-9: #8E4EC6FF;
 $the-scales-purple-step-12: #2B0E44FF;
 $the-scales-purple-step-7: #D3B4EDFF;
 $the-scales-purple-step-2: #FDFAFFFF;
 $the-scales-purple-step-5: #EDDBF9FF;
 $the-scales-purple-step-8: #BE93E4FF;
 $the-scales-purple-step-3: #F9F1FEFF;
 $the-scales-purple-step-1: #FEFCFEFF;
 $the-scales-purple-step-4: #F3E7FCFF;
 $the-scales-grass-step-12: #1B311EFF;
 $the-scales-grass-step-11: #297C3BFF;
 $the-scales-grass-step-10: #3D9A50FF;
 $the-scales-grass-step-9: #46A758FF;
 $the-scales-grass-step-8: #65BA75FF;
 $the-scales-grass-step-7: #97CF9CFF;
 $the-scales-grass-step-6: #B7DFBAFF;
 $the-scales-grass-step-5: #CEEBCFFF;
 $the-scales-grass-step-4: #DFF3DFFF;
 $the-scales-grass-step-3: #EBF9EBFF;
 $the-scales-grass-step-2: #F3FCF3FF;
 $the-scales-grass-step-1: #FBFEFBFF;
 $the-scales-red-step-10: #DC3D43FF;
 $the-scales-red-step-9: #E5484DFF;
 $the-scales-red-step-12: #381316FF;
 $the-scales-red-step-11: #CD2B31FF;
 $the-scales-red-step-8: #EB9091FF;
 $the-scales-red-step-7: #F3AEAFFF;
 $the-scales-red-step-6: #F9C6C6FF;
 $the-scales-red-step-5: #FDD8D8FF;
 $the-scales-red-step-4: #FFE5E5FF;
 $the-scales-red-step-3: #FFEFEFFF;
 $the-scales-red-step-2: #FFF8F8FF;
 $the-scales-red-step-1: #FFFCFCFF;
 $the-scales-plum-step-10: #A43CB4FF;
 $the-scales-plum-step-11: #9C2BADFF;
 $the-scales-plum-step-6: #EBC8EDFF;
 $the-scales-plum-step-9: #AB4ABAFF;
 $the-scales-plum-step-12: #340C3BFF;
 $the-scales-plum-step-7: #DFAFE3FF;
 $the-scales-plum-step-2: #FFF8FFFF;
 $the-scales-plum-step-8: #CF91D8FF;
 $the-scales-plum-step-3: #FCEFFCFF;
 $the-scales-plum-step-1: #FEFCFFFF;
 $the-scales-plum-step-4: #F9E5F9FF;
 $the-scales-plum-step-5: #F3D9F4FF;
 $the-scales-tomato-step-12: #341711FF;
 $the-scales-tomato-step-11: #CA3214FF;
 $the-scales-tomato-step-9: #E54D2EFF;
 $the-scales-tomato-step-8: #EA9280FF;
 $the-scales-tomato-step-7: #F3B0A2FF;
 $the-scales-tomato-step-6: #FAC7BEFF;
 $the-scales-tomato-step-5: #FDD8D3FF;
 $the-scales-tomato-step-4: #FFE6E2FF;
 $the-scales-tomato-step-10: #DB4324FF;
 $the-scales-tomato-step-3: #FFF0EEFF;
 $the-scales-tomato-step-2: #FFF8F7FF;
 $the-scales-tomato-step-1: #FFFCFCFF;
 $primary-o: #183762FF;
 $primary-l1: #3A5579FF;
 $primary-l2: #5D7391FF;
 $primary-l3: #8091A9FF;
 $primary-l4: #A3AFC0FF;
 $primary-l5: #C5CDD8FF;
 $primary-l6: #E7EBEFFF;
 $primary-d1: #112745FF;
 $primary-d2: #0A1627FF;
 $primary-d3: #050B14FF;
 $neutral-1: #F8F8F8FF;
 $neutral-2: #E8E8E8FF;
 $neutral-3: #C7C7C7FF;
 $neutral-4: #8F8F8FFF;
 $neutral-5: #858585FF;
 $neutral-6: #6F6F6FFF;
 $neutral-7: #505050FF;
 $neutral-8: #343434FF;
 $neutral-9: #282828FF;
 $neutral-10: #2A242BFF;
 $semantics-success-0: #17710CFF;
 $semantics-success-1: #5DB53EFF;
 $semantics-success-2: #8EDA68FF;
 $semantics-success-3: #E2F8CDFF;
 $semantics-info-0: #00206DFF;
 $semantics-info-1: #2C6BB2FF;
 $semantics-info-2: #569ED8FF;
 $semantics-info-3: #C6EAF8FF;
 $semantics-warning-1: #D8BD37FF;
 $semantics-warning-2: #EBD75FFF;
 $semantics-warning-3: #FBF6C9FF;
 $semantics-danger-0: #7A1135FF;
 $semantics-danger-3: #F9D5CFFF;
 $semantics-danger-2: #DD6E74FF;
 $semantics-danger-1: #BB4657FF;
 $semantics-warning-0: #A48300FF;

 $color-primary-gradient-l-0: linear-gradient(to bottom, #010407 0%,#17355f 51.65%,#3d8eff 100%);
 $color-primary-gradient-l-1: linear-gradient(to bottom, #010203 0%,#17355f 12.5%,#3d8eff 50.52%,#3d8eff 64.58%);

 :root {
  --reactour-accent: #3d8eff; /* Replace #yourColorValue with your desired color */
}
