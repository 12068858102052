@import '../../assets/styles/fonts';
@import '../../assets/styles/colors';
@import '../../assets/styles/constants';
@import '../../assets/styles/mixins';
.Button {
    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 8px;
        overflow: hidden;
        min-height: 40px;
    }
    .ant-btn-default:hover {
        color: $primary-o;
        border-color: $primary-o;
    }
    .ant-btn-primary {
        border-color: $primary-o;
        background: $primary-o;
        color: $white;
    }
    .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
        border-color: $primary-l4;
        background: $primary-l4;
    }
    [class^="icon-"], [class*=" icon-"] {
        display: block;
        margin-right: 8px;
    }
    .ant-btn-dangerous {
        background: $semantics-danger-3;
    }
}
.dark .Button {
    .ant-btn-primary {
        border-color: $white;
        color:  $neutral-9;
        background: transparent;
        border-width: 0;
        box-shadow: none;
        overflow: unset;
        &::after {
            content: '';
            height: 16px;
            width: 1px;
            background: $neutral-3;
            position: absolute;
            bottom: 0;
            left: -8px;
            transform: translateY(-50%);
        }
    }
    .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
        border-color: $primary-l4;
        background: $primary-l4;
    }
    [class^="icon-"], [class*=" icon-"] {
        display: block;
        margin-right: 8px;
    }
}

.dark2 .Button {
    .ant-btn-primary {
        border-color: $white;
        color:  $neutral-9;
        background: transparent;
        border-width: 0;
        box-shadow: none;
        overflow: unset;
        &::after {
            content: '';
            height: 16px;
            width: 1px;
            background: $neutral-3;
            position: absolute;
            bottom: 0;
            right: -8px;
            transform: translateY(-50%);
        }
        &:not(:last-child)::after {
            display: block;
        }
    }
    .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
        border-color: $primary-l4;
        background: $primary-l4;
    }
    [class^="icon-"], [class*=" icon-"] {
        display: block;
        margin-right: 8px;
    }
}

.option .Button {
    .ant-btn-primary {
        border-color: transparent;
        background: transparent !important;
        box-shadow: none;
        color: $neutral-9;
    }
    [class^="icon-"], [class*=" icon-"] {
        display: block;
        margin-right: 8px;
    }
}
.interation .Button {
    .ant-btn-primary {
        border-color: transparent;
        background: transparent;
        box-shadow: none;
        color: $neutral-9;
        padding: 8px;
        background: $primary-l6;
        display: flex;
        gap: 6px;
    }
    [class^="icon-"], [class*=" icon-"] {
        display: block;
        margin-right: 8px;
    }
}
